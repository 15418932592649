import { exists } from '../tools/exists';

// global
import './site-switcher';
import './basket';
import './header-events';
import './search';
import './discount-banner';
import './back-to-top';
import './qr-popup';
import './footer-events';

// blocks
if (exists('[data-behaviour="accordion"]')) {
    import('./accordion');
}

if (
    exists('[data-behaviour="get-img-height"]') &&
    exists('[data-behaviour="set-block-height"]')
) {
    import('./content-with-list');
}

if (
    exists('[data-behaviour="dropdown-select"]') &&
    exists('[data-behaviour="dropdown-links"]')
) {
    import('./easydropdown');
}

if (
    exists('[data-behaviour="video-play"]') &&
    exists('[data-behaviour="video-player"]') &&
    exists('[data-behaviour="video-container"]') &&
    exists('[data-behaviour="content-container"]')
) {
    import('./click-to-play');
}

if (exists('[data-behaviour="gravity-product-trigger"]') && exists('[data-behaviour="gravity-product-list"]')) {
    import('./gravity-form-list');
}

if (exists('[data-behaviour="read-more-trigger"]') && exists('[data-behaviour="read-more-container"]')) {
    import('./read-more');
}

if (exists('.u-tool-tip')) {
    import('./tooltip');
}

if (exists('[data-bevaviour="anchor-nav-item"]') && exists('[data-behaviour="dropdown-select"]')) {
    import('./tabs');
}

if (exists('[data-behaviour="open-share-panel"]') && exists('[data-behaviour="share-panel"]')) {
    import('./social-share');
}

if (exists('[data-behaviour="sort-by"]')) {
    import('./term-filters');
}

if (exists('[data-behaviour="slider"]')) {
    import('./slider');
}

if (exists('img.lazy')) {
    import('./intersection-observer');
}

// Shop
if (exists('[data-behaviour="update-cart"]')) {
    import('./add-to-cart');
}

if (exists('[data-behaviour="quantity"]')) {
    import('./product-quantity');
}

if (
    exists('[data-behaviour="related-products"]') ||
    exists('[data-behaviour="product-slider"]') ||
    exists('[data-behaviour="max-purchasable"]')
) {
    import('./product');
}

if (
    exists('[data-behaviour="open-filter"]') ||
    exists('[data-behaviour="filter-list-toggle"]') ||
    exists('[data-behaviour="product-filter"]')
) {
    import('./product-filters');
}

if (exists('[data-behaviour="removed_from_cart"]')) {
    import('./remove-product');
}

if (exists('[data-behaviour="woocommerce-message-close"]')) {
    import('./woo');
}

if (exists('input[maxlength]')) {
    import('./checkout');
}

if (exists('[data-behaviour="register-validate"]')) {
    import('./create-account-validate');
}

if (exists('[data-behaviour="tabs"]')) {
    import('./tabs');
}
